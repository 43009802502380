import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Location} from '@angular/common';
import { AuthService } from '../auth/auth.service';
import { AuthCustomerService } from '../services/auth-customer.service';
import { AuthUserService } from '../services/auth-user.service';
import { CompanyService } from '../services/company.service';
import { SideMenuService } from '../services/side-menu.service';
import { NotificationsService } from '../services/staff/notifications.service';
import { Platform } from '@ionic/angular';



interface MenuItems{
  title:string;
  linkHref:string;
  icon:string;
}

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})


export class SideMenuComponent implements OnInit , OnDestroy{
  choseMenuSubscription:Subscription;
  changeNrUnvisitedNotifications:Subscription;
  resumSubscription:Subscription;
  syncCustomerSubscription:Subscription;
  menuItems: MenuItems[];
  
  activeMenu:string;
  customerMenu:string;
  staffMenu:string;
  destroyMenu:boolean;
  customerLoggetIn = false;
  nrUnvisitedNotifications:number;
  classUnvisited:string;

  constructor(
    private authService: AuthService,
    private menuService: SideMenuService,
    private staffAuthService: AuthUserService,
    private companyService: CompanyService,
    private router: Router,
    private _cdr: ChangeDetectorRef,
    private authCustomerService: AuthCustomerService,
    private staffNotificationsService: NotificationsService,
    private location: Location,
    private platform: Platform,
    
  ) { }
  

  ngOnInit() {
    // this.activeMenu = 'customer'
    // this.customerMenu = 'main';
    // this.staffMenu = 'off';
    this.destroyMenu = false;
    this.nrUnvisitedNotifications=0;
    this.classUnvisited='hide';

    this.choseMenuSubscription = this.menuService.choseMenu.subscribe( activeMenu => {

        this.activeMenu = activeMenu;
        if(activeMenu === 'staff'){
        
        }else if(activeMenu === 'customer'){
          
          this.authCustomerService.customer.then(customer => {
            if(customer)
            {
              this.customerLoggetIn = true;
            }else{
              this.customerLoggetIn = false;
            }
          });

        }else{
          
        }
      //  this._cdr.detectChanges();
    });

    this.checkWitchMenuOnStart();

    
    // if(this.location.path() !== '/company/company'){
    //   this.authCustomerService.customer.then(customer => {
    //     console.log(customer);
    //     if(customer){
    //       this.authCustomerService.syncCustomer(customer).subscribe(
    //         {
    //           next: customerSync => {

    //               console.log(customerSync);
    //               if(+customerSync.unvisitedNrNotifications === 0){
    //                 this.nrUnvisitedNotifications = 0;
    //                 this.classUnvisited='hide';
    //                 this._cdr.detectChanges();

    //               }else if(+customerSync.unvisitedNrNotifications > 0){
    //                 this.nrUnvisitedNotifications = +customerSync.unvisitedNrNotifications;
    //                 this.classUnvisited='show';
    //                 this._cdr.detectChanges();
    //               }
                  
    //           },
      
    //           error: err => {
    //             console.log(err);
    //           }
    //         }
    //       );
    //     }
    //   });
    // }

    this.resumSubscription = this.platform.resume.subscribe(()=>{
      if(this.location.path() !== '/company/company'){
        this.authCustomerService.customer.then(customer => {
          

          if(customer){
            if(this.syncCustomerSubscription){
              this.syncCustomerSubscription.unsubscribe();
            }

            this.syncCustomerSubscription = this.authCustomerService.syncCustomer(customer).subscribe(
              {
                next: customerSync => {


                  if(+customerSync.unvisitedNrNotifications === 0){
                    this.nrUnvisitedNotifications = 0;
                    this.classUnvisited='hide';
                    this._cdr.detectChanges();

                  }else if(+customerSync.unvisitedNrNotifications > 0){
                    this.nrUnvisitedNotifications = +customerSync.unvisitedNrNotifications;
                    this.classUnvisited='show';
                    this._cdr.detectChanges();
                  }
                  
              },
        
                error: err => {
                  console.log(err);
                }
              }
            );
          }
        });
      }
    });

    this.staffNotificationsService.onStaffNotification().subscribe({
      next: notification =>{
        if(this.location.path() !== '/company/company')
        {
          if(notification.type === 'edit' || notification.type === 'new'){
            this.nrUnvisitedNotifications +=1 ;
            this.classUnvisited='show';
            this._cdr.detectChanges();
            
    
    
    
              // this.currentShowNotifications.unshift(notification)
            }else if(notification.type === 'delete'){
              
             
            }
        }
       
      },
      error: err =>{
        console.log(err);
      }
      
    });

    this.staffNotificationsService.resetStaffNotificationCounter.subscribe(data =>{
      if(data === "reset")
      {
        this.nrUnvisitedNotifications=0;
        this.classUnvisited='hide';
      }
    });
    
    
  }

  

  checkWitchMenuOnStart(){
    return this.staffAuthService.getStaff().then(user =>{
      if(!user){
        return this.companyService.getCompany().then(company =>{

          if(company){
            //log in as costumer
            this.menuService.onActivateMenu('customer');

            this.authCustomerService.customer.then(customer => {
              if(customer)
              {
                this.customerLoggetIn = true;
              }else{
                this.customerLoggetIn = false;
              }
            });

          }else{
            //Not login
            this.menuService.onActivateMenu(null);
          }
          //not login
        });
        
      }else{
        //log in ass staff
        this.menuService.onActivateMenu('staff');
      }
    })
  }

  onLogout(){
    this.authService.logOut().then(()=>{
      this.router.navigateByUrl('/auth');
    });
    this.destroyMenu = true;
   
  }

  onLogoutStaff(){
    
    this.staffAuthService.logOut().then(()=>{
      this.router.navigateByUrl('/auth');
    });
    this.destroyMenu = true;
  }


  onIonDidClose(){
    if(this.destroyMenu){
      this.menuService.onDestroyMenu();
      this.destroyMenu = false;
    }
  }

  ngOnDestroy(): void {
    this.choseMenuSubscription.unsubscribe();
    if(this.changeNrUnvisitedNotifications){
      this.changeNrUnvisitedNotifications.unsubscribe();
    }
    if(this.syncCustomerSubscription){
      this.syncCustomerSubscription.unsubscribe();
    }
    this.resumSubscription.unsubscribe();
  }

}
