import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SideMenuService {
  private _choseMenu: Subject<string> = new Subject();
  choseMenu: Observable<string> = this._choseMenu.asObservable();

  constructor(
    
  ) {}

  onActivateMenu(menu:string){
    this._choseMenu.next(menu);
  }

  onDestroyMenu(){
    this._choseMenu.next(null);
  }
}
