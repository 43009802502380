import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { map, take, tap } from 'rxjs/operators';
import { apiResposeType } from '../models/apiResposeType';
import { Company } from '../models/company.model';
import { User } from '../models/user.model';
import { CompanyService } from './company.service';
import { ConstantsService } from './constants.service';
import { PushNotificationsService } from './push-notifications.service';

@Injectable({
  providedIn: 'root'
})
export class AuthUserService {
  //Staff

  private _user:User;

  constructor(
    private http: HttpClient,
    private glabalsConst:ConstantsService,
    private storage:Storage,
    private companyService: CompanyService,
    private pushNotifications: PushNotificationsService
  ) { }

  getUser():Promise<User>{
    return new Promise((resolve,reject)=>{
      if(this._user){
        resolve(this._user)
      }else{
        return this.storage.get('user').then(user => {
          this._user = user;
          resolve(user);
        });
      }
    })
  }


  appointmentChanged(){
    return this.pushNotifications.appointmentChanged
  }

  newAppointment(){
    return this.pushNotifications.newAppointment
  }

  appointmentDeleated(){
    return this.pushNotifications.appointmentDeleated
  }

  signIn(email: string, password: string){
    return this.http.post<apiResposeType>(this.glabalsConst.baseAppUrl + 'login', {email: email, password: password} ).pipe(
      map((data) => {
        

        if(data.success){
          const user:User = {
             id:data.data.user.id,
             companyId:data.data.user.company_id,
             roleId: data.data.user.role_id,
             name:data.data.user.name,
             lastName: data.data.user.last_name,
             email: data.data.user.email,
             phonNumber:data.data.user.phone_number,
             avatar: data.data.user.avatar,
             openingHours:data.data.user.opening_hours_data,
             token: data.data.token,
             fbToken: null,
             description: data.data.user.description
          }

          const company:Company = {
             id:data.data.company.id,
             name: data.data.company.name,
             logo:data.data.company.logo,
             phone: data.data.company.phone,
             body: data.data.company.body,
             partitaIVA: data.data.company.partitaIVA,
             address: data.data.company.address,
             openingHours: JSON.parse(data.data.company.openingHours),
         }

          return {user: user, company: company}
        }else{
          return null;
        }  
      }),
      tap((data) => {
        this.storeCompanyAndUser({company:data.company, user:data.user});
      })
    ); 
  }

  storeCompanyAndUser(data:{company:Company, user:User}){

    this.companyService.storeCompany(data.company).then((company)=>{
      if(company == data.company){
        this.storeStaff(data.user);
        this.initFirebaseNotificationsForStaff();
      }
    })
  }

  storeStaff(staff:User): Promise<User> {

    return this.storage.set('user', staff).then( respose => {
      if(respose === staff){
        this._user = staff;
        return respose;
      }else{
        return null;
      }
    });
  }

  getStaff():Promise<User>{
    return new Promise((resolve,reject)=>{
      if(this._user){
        resolve(this._user)
      }else{
        return this.storage.get('user').then(user => {
          this._user = user;
          resolve(user);
        });
      }
    })
  }

  removeStaff(){
    // return this.storage.remove('user').then(()=>{
    //   this._user= null;
    //   return null;
    // });

    return new Promise((resolve,reject)=>{

      //Remove token Associated with user
      this.getStaff().then(user => {

        if(user.fbToken){
          
          this.removeFBTokenForUser(user).pipe(take(1)).subscribe(
            {
              next: resp => {
                // console.log(resp);
              },
              error: err => {
                console.log(err);
              }
            }
          );
        }

        this.storage.remove('user').then(user => {
          this._user = null;
          resolve(user);
        });
      });  
    })
  }

  logOut(){
    //console.log('logout auth-user');
    return this.companyService.removeCompany().then(()=>{
      this.removeStaff().then(()=>{
        return null;        
      });
    });
  }

  
  initFirebaseNotificationsForStaff(){
    this.pushNotifications.checkOrAskPushPermission().then(permission =>{
      if(permission){

       this.pushNotifications.getToken().then(token => {
        if(token !== null){
          this.getUser().then(user => {
            if(user){
              /* console.log('Old from get' + user.fbToken);
              console.log('New from get' + token); */
              if(user.fbToken !== token){
                //Save the new token
                this.saveNewFBTokenForUser(user, token);

              }else{
                //Token are same, do nothing

              }
            }

          });
        }

      });

        this.pushNotifications.onTokenRefresh().subscribe({
          next: token => {
            // Check if customer Token is same as online Token

            this.getUser().then(user => {
              if(user){
                // console.log('Old ' + user.fbToken);
                // console.log('New ' + token);
                if(user.fbToken !== token){
                  //Save the new token
                  this.saveNewFBTokenForUser(user, token);

                }else{
                  //Token are same, do nothing

                }


              }

            });

          },

          error: err => {
            console.log(err);
          }
        });


        this.pushNotifications.onMessageReceived().subscribe(data => {
          //Handler messages
          // console.log(`User opened a notification `);
          // console.log(data);
        });

      }else{
        // console.log('Dont have permission for push notification')
      }
    });
  }

  saveNewFBTokenForUser(user: User, newToken:string){
    

    this.pushNotifications.saveToken(user, null, newToken).subscribe(
      {
        next: resp => {
          //resp is null
          //console.log(resp);
          if(resp.success){
            
            this._user.fbToken = newToken;
            this.storeStaff(this._user);

          }
        },
        error: err => {
          console.log(err);
        }
      }

    );
  }

  removeFBTokenForUser(user: User){
    return this.pushNotifications.removeToken(user, null)
  }

}
