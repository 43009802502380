import { Injectable } from '@angular/core';
import { QRScanner, QRScannerStatus } from '@ionic-native/qr-scanner/ngx';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from '../services/constants.service';
import { map } from 'rxjs/operators';
import { apiResposeType } from '../models/apiResposeType';
import { CompanyService } from '../services/company.service';
import { AuthCustomerService } from '../services/auth-customer.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _hasCompany = false;
  QRSCANNED_DATA: string;
  isOn = false;
  qrScanerIsOn = new BehaviorSubject<boolean>(false);
  scanSub:Subscription;

  private _companyId: Subject<string> = new Subject();
  companyId: Observable<string> = this._companyId.asObservable();


  get userHasCompany(){

    return this._hasCompany;
  }

  constructor( 
    private qrScanCtrl: QRScanner, 
    private http: HttpClient,
    private glabalsConst:ConstantsService,

    private companyService: CompanyService ,
    private authCustomerService: AuthCustomerService,
    ) { }

  scan(){
    const ionApp = document.getElementsByTagName('ion-app')[0] as HTMLElement;
    this.qrScanCtrl.prepare()
      .then((status: QRScannerStatus) => {
        ionApp.style.display = 'block';

        if (status.authorized) {
          // camera permission was granted
          this.isOn = true;
          this.qrScanerIsOn.next(true);


          // start scanning
          const scanSub = this.qrScanCtrl.scan().subscribe((text: string) => {

            this.QRSCANNED_DATA = text;
            
            if (this.QRSCANNED_DATA !== '') {
/*               ionApp.style.display = 'block';*/
              this.closeScanner();
              scanSub.unsubscribe();
            }

          });
          /* ionApp.style.display = 'none'; */
          this.qrScanCtrl.show();

        } else if (status.denied) {
          this.QRSCANNED_DATA = 'camera permission denied';
          this.qrScanCtrl.openSettings();
        } else {
        }
      })
      .catch((e: any) => console.log('Error is: ' + e ));


    this._hasCompany = true;
  }

  closeScanner() {
    this.qrScanerIsOn.next(false);
    this.isOn = false;
    this.qrScanCtrl.hide();
    this.qrScanCtrl.destroy();
    
  }

  validateScanerResult(){
   
    let dataToValidate = this.QRSCANNED_DATA.split('-');
    
    if(dataToValidate[0] !== 'GecSoftware' ||  isNaN(parseInt(dataToValidate[1]))){
      this.QRSCANNED_DATA = '';
      return {valid: false, id:null, staff:false}

    }else{
      /* this._hasCompany = true;
      this.router.navigateByUrl('/company'); */
      if(dataToValidate[2] === 'Staff'){
        return {valid: true, id:dataToValidate[1], staff: true};
      }else{
        return {valid: true, id:dataToValidate[1], staff: false};
      } 
    }
  }

  fetchCompany(id: string){
      return this.http.get<apiResposeType>(this.glabalsConst.baseAppUrl + 'companies/' + id)
      .pipe(
        map(respose => {
          if(!respose || !respose.success){
            return null;
          }
          this._hasCompany = true;
          respose.data.openingHours = JSON.parse(respose.data.openingHours);
          return respose;

        })
    );
  }

  logOut(){
    return this.companyService.removeCompany().then(()=>{
      this.authCustomerService.removeCustomer();
      this.QRSCANNED_DATA = ''  
      this._hasCompany = false;
      return null;
    });
  }
}
