import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthUserGuard } from './guards/auth-user.guard';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'company',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then( m => m.AuthPageModule)
  },
  {
    path: 'company',
    loadChildren: () => import('./company/company.module').then( m => m.CompanyPageModule),
    canLoad: [AuthGuard],
    canActivate:[AuthGuard]
  },
  {
    path: 'staff',
    loadChildren: () => import('./staff/staff.module').then( m => m.StaffPageModule),
    canLoad:[AuthUserGuard],
    canActivate:[AuthUserGuard]
  },
  {
    path: 'auth-user',
    loadChildren: () => import('./auth-user/auth-user.module').then( m => m.AuthUserPageModule)
  },
  {
    path: 'policy-agreement',
    loadChildren: () => import('./policy-agreement/policy-agreement.module').then( m => m.PolicyAgreementPageModule)
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () => import('./terms-and-conditions/terms-and-conditions.module').then( m => m.TermsAndConditionsPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'informazioni',
    loadChildren: () => import('./informazioni/informazioni.module').then( m => m.InformazioniPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
