import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { apiResposeType } from '../models/apiResposeType';
import { Appointment } from '../models/appointment.model';
import { Category } from '../models/category.model';
import { Customer } from '../models/customer.model';
import { Product } from '../models/product.model';
import { User } from '../models/user.model';
import { ConstantsService } from './constants.service';

interface AvailableStaff
{
  id_staff:number,
  name_staff:string,
  last_name_staff?:string,
  avatar_staff:string,
  opening_hours_data:string[],
  opening_hours_efective?:string[],
  staff_appointments:{
    [appointment_id: string]:{
      id_appointment:number,
      appointment_date:string,
      appointment_product_id:number,
      appointment_product_duration:string
    }
  }
}

interface AvailableStaffs
{
  [idStaff:string]:AvailableStaff
}

interface AvailabeleOHforDate
{
  companyOH: string[],
  companyOHWithStaff:string[],
  availableStaff:AvailableStaffs
}

interface AvailableHTodayAndTomorow
{
  'days':{
  'today': AvailabeleOHforDate,
  'tomorrow': AvailabeleOHforDate,
  'otherDate'?:AvailabeleOHforDate
      }
  'todayDate':string,
  'companyWeekDaysClosed':number[]
}

@Injectable({
  providedIn: 'root'
})
export class AppointmentsService {
  categories:Category[];
  products: Product[];
  openedModals:string[] =  [];
  localCustomerAppointments:Appointment[];
  categoriesLight:Record<string ,Category >;

  private _customerAppointments: Subject<Record<string ,Appointment[] >> = new Subject();
  customerAppointments: Observable<Record<string ,Appointment[] >> = this._customerAppointments.asObservable();

  private _choseCategory: Subject<Category> = new Subject();
  choseCategory: Observable<Category> = this._choseCategory.asObservable();

  private _detailProduct: Subject<Product> = new Subject();
  detailProduct: Observable<Product> = this._detailProduct.asObservable();

  private _addProduct: Subject<Product> = new Subject();
  addProduct: Observable<Product> = this._addProduct.asObservable();

  private _addProductAndDate: Subject<{product:Product, date:string}> = new Subject();
  addProductAndDate: Observable<{product:Product, date:string}> = this._addProductAndDate.asObservable();

  private _edditAppointment: Subject<Appointment> = new Subject();
  edditAppointment: Observable<Appointment> = this._edditAppointment.asObservable();

  private _closeModal: Subject<string> = new Subject();
  closeModal: Observable<string> = this._closeModal.asObservable();

  private _showtToast: Subject<string> = new Subject();
  showtToast: Observable<string> = this._showtToast.asObservable();

  private _updateAppointmentPage: Subject<string> = new Subject();
  updateAppointmentPage: Observable<string> = this._updateAppointmentPage.asObservable();

  private _availableHTodayAndTomorow = new BehaviorSubject < AvailableHTodayAndTomorow > (null);
  private _availableHForDate = new BehaviorSubject < string[] > ([]);

  todayDate:string;
  companyWeekDaysClosed:number[];

  
  constructor(
    private modalCtrl: ModalController,
    private http: HttpClient,
    private glabalsConst:ConstantsService
    ) { }


  get availableHTodayAndTomorow(){
    return this._availableHTodayAndTomorow.asObservable()
  }
  onChoseCategory(category:Category){
    this._choseCategory.next(category);
  }

  onDetailProduct(product: Product){
    this._detailProduct.next(product);
  }

  onAddProduct(product:Product){
    this._addProduct.next(product);
  }

  onAddProductAndDate(product:Product, date:string){
    this._addProductAndDate.next({product:product, date:date});
  }

  onEdditAppointment(appointment: Appointment){
    this._edditAppointment.next(appointment);
  }

  onUpdateAppointmentPage(dateInput: string){
    this._updateAppointmentPage.next(dateInput);
  }

  fetchAvailableHForTodayAndTomorrow(companyId:string, productId:string){ 
    return this.http.get<apiResposeType>(this.glabalsConst.baseAppUrl + 'companies/' + companyId + '/products/'+ productId + '/appointments',{params:{tz:Intl.DateTimeFormat().resolvedOptions().timeZone}}).pipe(
      
      map((data) => {
        if(!data.success){
          return null;
        }

        this.todayDate = data.data.todayDate;
        
        this.companyWeekDaysClosed = data.data.companyWeekDaysClosed.map( item => parseInt(item));
        
        let currentTime = moment();
      
        for(let day in data.data.days)
        {

          if(data.data.days[day].hasOwnProperty('availableStaff'))
          {
            let indexesToRemoveFromCompany = this.removeExtremTimesForProductDuration([...data.data.days[day].companyOH], data.data.days[day].productDuration);
            data.data.days[day].companyOH = [...data.data.days[day].companyOH].filter((item, index)=>{ 
              if(indexesToRemoveFromCompany.indexOf(index) === -1){
                return true
              }else{
                return false;
              }
            });
            
            for(let key in data.data.days[day].availableStaff){

              if(data.data.days[day].availableStaff[key].hasOwnProperty('staff_appointments'))
              {
                
                //Remove appointmants duration
                for(let appointmentId in data.data.days[day].availableStaff[key].staff_appointments){
                  
                  let startAppointment = moment(data.data.days[day].availableStaff[key].staff_appointments[appointmentId].appointment_date).format('HH:mm');
                  let duration = data.data.days[day].availableStaff[key].staff_appointments[appointmentId].appointment_product_duration;
                  data.data.days[day].availableStaff[key].opening_hours_data = this.removeOverlappingTime(data.data.days[day].availableStaff[key].opening_hours_data, startAppointment, duration);
                }

                //Remove today past H
                if(day === 'today'){

                  data.data.days[day].availableStaff[key].opening_hours_data = [...data.data.days[day].availableStaff[key].opening_hours_data].filter((time =>{

                    const appointmentTime = moment(time, 'HH:mm');
                    if(appointmentTime.isBefore(currentTime)){
                      return false;
                    }
                    return true;
                  }));
                }
    
                //remove current product extr duration
                let indexesToRemove = this.removeExtremTimesForProductDuration(data.data.days[day].availableStaff[key].opening_hours_data, data.data.days[day].productDuration);
         
                  data.data.days[day].availableStaff[key].opening_hours_data = [...data.data.days[day].availableStaff[key].opening_hours_data].filter((item, index)=>{ 
                    if(indexesToRemove.indexOf(index) === -1){
                      return true
                    }else{
                      return false;
                    }
                  });

                  // console.log(data.data.days[day].availableStaff[key].name_staff + day);
                  // console.log(data.data.days[day].availableStaff[key].opening_hours_data);
                  // console.log(data.data.days[day].companyOH);
                  // console.log();

                  data.data.days[day].availableStaff[key].opening_hours_efective;
                  data.data.days[day].availableStaff[key].opening_hours_efective = this.intersectStaffOHWithCompanyOH([...data.data.days[day].availableStaff[key].opening_hours_data],[...data.data.days[day].companyOH]);
              }
    
            }
          
            data.data.days[day].companyOHWithStaff = this.availableHCombinHCompanyHstaff({...data.data.days[day]}); 
          }else{
            data.data.days[day].companyOHWithStaff = [];
          }
        }
        // console.log(data.data);
        return data.data;
        
      }),
      tap((availableH) => {
        this.updateAvailableHTodayAndTomorow(availableH);
      })
    );  
 }

 intersectStaffOHWithCompanyOH(oHStaff:string[], oHCompany:string[]){
    return oHStaff.filter(item => oHCompany.indexOf(item) !== -1);
 }

 availableHCombinHCompanyHstaff(availableHForDate:AvailabeleOHforDate){
  // console.log(availableHForDate);
  var staffsAvailableH = [];
  // console.log(this.availabeleOHforDate);
  if(availableHForDate.hasOwnProperty('availableStaff')){
    for(const key in availableHForDate.availableStaff){
      staffsAvailableH = staffsAvailableH.concat(availableHForDate.availableStaff[key].opening_hours_data.filter( item => staffsAvailableH.indexOf(item) < 0)).sort();  
    }
  }else{
    return [];
  }

  // console.log(staffsAvailableH);
   //Intersect with company h
  let availableH = availableHForDate.companyOH.filter(item => staffsAvailableH.indexOf(item) !== -1);
  // console.log(availableHForDate.companyOH);
  // console.log(availableH);
  
  return availableH;
  
  // this.availableHours = availableH;
  
}

 fetchAvailableHForTodayAndTomorrowAndCustomDate(companyId:string, productId:string, date:string, appointment: Appointment = null){

  let data = {
    params: {tz:Intl.DateTimeFormat().resolvedOptions().timeZone},
    date : date,
    appointment_id: appointment ? appointment.id : ''
  }
  

  return this.http.post<apiResposeType>(this.glabalsConst.baseAppUrl + 'companies/' + companyId + '/products/'+ productId + '/appointments/date',data).pipe(
      
    map((data) => {

      if(!data.success){
        return null;
      }
      

      this.todayDate = data.data.todayDate;
      
      this.companyWeekDaysClosed = data.data.companyWeekDaysClosed.map( item => parseInt(item));
      
      let currentTime = moment();
    
      for(let day in data.data.days)
      {
        if(data.data.days[day].hasOwnProperty('availableStaff'))
        {
          
          let indexesToRemoveFromCompany = this.removeExtremTimesForProductDuration([...data.data.days[day].companyOH], data.data.days[day].productDuration);
          data.data.days[day].companyOH = [...data.data.days[day].companyOH].filter((item, index)=>{ 
            if(indexesToRemoveFromCompany.indexOf(index) === -1){
              return true
            }else{
              return false;
            }
          });
          
          for(let key in data.data.days[day].availableStaff){

            if(data.data.days[day].availableStaff[key].hasOwnProperty('staff_appointments'))
            {
              
              //Remove appointmants duration
              for(let appointmentId in data.data.days[day].availableStaff[key].staff_appointments){
                
                let startAppointment = moment(data.data.days[day].availableStaff[key].staff_appointments[appointmentId].appointment_date).format('HH:mm');
                let duration = data.data.days[day].availableStaff[key].staff_appointments[appointmentId].appointment_product_duration;
                data.data.days[day].availableStaff[key].opening_hours_data = this.removeOverlappingTime(data.data.days[day].availableStaff[key].opening_hours_data, startAppointment, duration);
              }

              //Remove today past H
              if(day === 'today'){

                data.data.days[day].availableStaff[key].opening_hours_data = [...data.data.days[day].availableStaff[key].opening_hours_data].filter((time =>{

                  const appointmentTime = moment(time, 'HH:mm');
                  if(appointmentTime.isBefore(currentTime)){
                    return false;
                  }
                  return true;
                }));
              }
  
              //remove current product extr duration
              let indexesToRemove = this.removeExtremTimesForProductDuration(data.data.days[day].availableStaff[key].opening_hours_data, data.data.days[day].productDuration);
       
                data.data.days[day].availableStaff[key].opening_hours_data = [...data.data.days[day].availableStaff[key].opening_hours_data].filter((item, index)=>{ 
                  if(indexesToRemove.indexOf(index) === -1){
                    return true
                  }else{
                    return false;
                  }
                });
                data.data.days[day].availableStaff[key].opening_hours_efective;
                data.data.days[day].availableStaff[key].opening_hours_efective = this.intersectStaffOHWithCompanyOH([...data.data.days[day].availableStaff[key].opening_hours_data],[...data.data.days[day].companyOH]);
            }
           
          }
            
          data.data.days[day].companyOHWithStaff = this.availableHCombinHCompanyHstaff({...data.data.days[day]}); 
        }else{
          data.data.days[day].companyOHWithStaff = [];
        }
      }

      return data.data;
      
    }),
    tap((availableH) => {
      this.updateAvailableHTodayAndTomorow(availableH);
    })
  ); 
 }

 fetchAvailableHForDate(companyId:string, productId:string, date:string, ifToday = false, appointment:Appointment = null){

  let appointmentId = appointment ? appointment.id : null;
  
  return this.http.post<apiResposeType>(this.glabalsConst.baseAppUrl + 'companies/' + companyId + '/products/'+ productId + '/appointments', {date:date, appointmett_id: appointmentId}).pipe(
    map((data) => {

      if(!data.success){
        return null;
      }
      // console.log(data);

      //console.log(this.removeOverlappingTime(["09:00-09:15", "09:15-09:30", "09:30-09:45", "09:45-10:00", "10:00-10:15", "10:15-10:30", "10:30-10:45", "10:45-11:00", "11:00-11:15", "11:15-11:30", "11:30-11:45", "11:45-12:00", "12:00-12:15", "12:15-12:30"], "09:00", '32'));
      
      if(data.data.hasOwnProperty('availableStaff'))
      {

        let indexesToRemoveFromCompany = this.removeExtremTimesForProductDuration(data.data.companyOH, data.data.productDuration);
        data.data.companyOH = [...data.data.companyOH].filter((item, index)=>{ 
          if(indexesToRemoveFromCompany.indexOf(index) === -1){
            return true
          }else{
            return false;
          }
        });

        
        for(const key in data.data.availableStaff){
          if(data.data.availableStaff[key].hasOwnProperty('staff_appointments'))
          {
              //Remove appointmants duration
            for(const appointmentId in data.data.availableStaff[key].staff_appointments){

              let startAppointment = moment(data.data.availableStaff[key].staff_appointments[appointmentId].appointment_date).format('HH:mm');
              let duration = data.data.availableStaff[key].staff_appointments[appointmentId].appointment_product_duration;
              //console.log(this.removeOverlappingTime(data.data.availableStaff[key].opening_hours_data, startAppointment, duration));

              data.data.availableStaff[key].opening_hours_data = this.removeOverlappingTime(data.data.availableStaff[key].opening_hours_data, startAppointment, duration);
            }

            //Remove today past H
         
            if(ifToday){
              let currentTime = moment();
              data.data.availableStaff[key].opening_hours_data = [...data.data.availableStaff[key].opening_hours_data].filter((time =>{

                const appointmentTime = moment(time, 'HH:mm');
                if(appointmentTime.isBefore(currentTime)){
                  return false;
                }
                return true;
              }));
            }

            //remove current product extr duration
            let indexesToRemove = this.removeExtremTimesForProductDuration(data.data.availableStaff[key].opening_hours_data, data.data.productDuration);
     
              data.data.availableStaff[key].opening_hours_data = [...data.data.availableStaff[key].opening_hours_data].filter((item, index)=>{ 
                if(indexesToRemove.indexOf(index) === -1){
                  return true
                }else{
                  return false;
                }
              });

              data.data.availableStaff[key].opening_hours_efective;
              data.data.availableStaff[key].opening_hours_efective = this.intersectStaffOHWithCompanyOH([...data.data.availableStaff[key].opening_hours_data],[...data.data.companyOH]);
          }

        }
        data.data.companyOHWithStaff = this.availableHCombinHCompanyHstaff({...data.data});
        
      }else{
        data.data.companyOHWithStaff = [];
      }
      
      // console.log(data.data);
      return data.data; 
    }),
  
  );  
}    

 fetchAppointmentById(id: number, customer: Customer){

  const headers = { 'Accept': 'application/json', 'Authorization': 'Bearer '  + customer.token};

  const data = {
    'company_id' : customer.company_id,
    'customer_id' : customer.customerId,
    'appointment_id' : id
  }

  return this.http.put<apiResposeType>(this.glabalsConst.baseAppUrl + 'customer/appointment', data, {headers}).pipe(
    map(resp => {
      if(!resp.success){
        return null;
      }
      // console.log(resp);
      const appointment:Appointment = resp.data.appointment;
        this.categoriesLight = resp.data.categories;
        this.categories = Object.values(this.categoriesLight);
        
        appointment.color = this.getCategoryTopLevelParent(appointment.product.categoryId,  this.categories)
        return appointment; 
 
    })
  );
 }

 getCategoryTopLevelParent(categoryId:string, categories:Category[]){
  let category:Category = categories.find(cat =>{
    return +categoryId === +cat.id;
  } );
 
  // console.log(category);
  while(category.parentId !== null){
    
    category = categories.find(cat =>{
      return +category.parentId === +cat.id;
    });
  }
  return category.color;    
}

 deleatAppointmentById(customer: Customer, appointment:Appointment, motivazione: string){
  const headers = { 'Accept': 'application/json', 'Authorization': 'Bearer '  + customer.token};

  return this.http.delete<apiResposeType>(this.glabalsConst.baseAppUrl + 'customer/appointment',  
  {
    headers:headers,
    params:{ 
      'customer_name': customer.name + ' ' + customer.surname ,
      'appointment_id': appointment.id,
      'appointment_date': appointment.date,
      'product_name': appointment.product.name,
      'staff_id': appointment.staff.id,
      'motivazione': motivazione,
      'company_id':appointment.company_id
    }
  }).pipe(take(1));

 }


 fetchAppointmentsForCustomer(customer: Customer){

  if(!customer){
    throw new Error('No customer found');
  }

  moment.locale('it-it');
  const dateTime = moment();
  const headers = { 'Accept': 'application/json', 'Authorization': 'Bearer '  + customer.token};

  const data = {
    'company_id' : customer.company_id,
    'customer_id' : customer.customerId,
    'date' : dateTime.format('YYYY-MM-DD HH:mm:ss')
  }

  return this.http.put<apiResposeType>(this.glabalsConst.baseAppUrl + 'customer/appointments', data, {headers}).pipe(
    map(resp => {
      
      if(!resp.success || resp.data.length === 0){
        return null;
      }

      this.localCustomerAppointments = [...resp.data];
      // console.log(this.localCustomerAppointments);

      if(resp.data.length !== 0){
        let groupedDATA = {};
        moment.locale('it-it');
        for(let i = 0; i < resp.data.length; i ++){
          let date = moment(resp.data[i].date, 'YYYY-MM-DD HH:mm:ss');
          let day = date.format('YYYY-MM-DD');
          
          if(groupedDATA[day] !== undefined ){
            groupedDATA[day].push(resp.data[i])
          }else{
            groupedDATA[day] = [resp.data[i]];
          }
        }

        return groupedDATA;
      }else {
        return null;
      }
      
    }), tap({
        next: appointmensts => {
          
          this._customerAppointments.next(appointmensts);
      
        },
        error : err =>{
          this._customerAppointments.next(null);
          console.log(err);
        }
      })
  );
 }


 updateAvailableHTodayAndTomorow(availableH){
  this._availableHTodayAndTomorow.next(availableH);
 }

testremoveExtremTimesForProductDuration(){
  this.removeExtremTimesForProductDuration(["09:15", "09:30" , "10:15", "10:30", "10:45", "11:00", "11:15", "11:30", "11:45", "12:00", "12:15", "12:30", "14:00", "14:15", "14:30", "14:45", "15:00", "15:15", "15:30", "15:45", "16:00", "16:15", "16:30", "16:45", "17:00", "17:15", "17:30"], '46');
}

removeExtremTimesForProductDuration(times:string[], duration:string){
  
  if(parseInt(duration) > 15)
  {
  

    //Get extremes indexes inside
    let extremIndexes = [];
    let indexesToRemoveBeforeExtremes = [];

    for(let i = 0 ; i < times.length; i++){

      if(i !== (times.length - 1))
      {
        let timeStart =  moment(times[i],'HH:mm');
        let timeEnd = moment(times[i + 1],'HH:mm').subtract(15, 'minutes');
        if(!timeStart.isSame(timeEnd))
        {
            extremIndexes.push(i);
        }
      }else{
        
        extremIndexes.push(i);
        
      }
    }

    if(extremIndexes.length !== 0){
      for(let i = 0 ; i < extremIndexes.length; i++)
      {

        let extTimeD = moment(times[extremIndexes[i]],'HH:mm').subtract(duration , 'minutes').add(15, 'minutes');

        if(extremIndexes[i] !== 0){
          
          for(let j = (extremIndexes[i] - 1) ; j >= 0 ; j--)
          {
            let timeSM = moment(times[j],'HH:mm');
        
            
            if( extTimeD.isBefore(timeSM)){
              indexesToRemoveBeforeExtremes.push(j);
              //return false;
            }

          }
        }
      }
    }
    // console.log(extremIndexes, indexesToRemoveBeforeExtremes);
    return(extremIndexes.concat(indexesToRemoveBeforeExtremes));
  }
   
 return [];
}

testremoveOverlappingTime(){

  let times = this.removeOverlappingTime([
    "09:00",
    "09:15",
    "09:30",
    "09:45",
    "10:00",
    "10:15",
    "10:30",
    "10:45",
    "11:00",
    "11:15",
    "11:30",
    "11:45",
    "14:00",
    "14:15",
    "14:30",
    "14:45",
    "15:00",
    "15:15",
    "15:30",
    "15:45",
    "16:00",
    "16:15",
    "16:30",
    "16:45",
    "17:00",
    "17:15",
    "17:30",
    "17:45"
], '10:00', '18');

times = this.removeOverlappingTime(times, '14:30', '160');
 

let indexesToRemove = this.removeExtremTimesForProductDuration(times,'18');
 times = [...times].filter((item, index)=>{ 
  if(indexesToRemove.indexOf(index) === -1){
    return true
  }else{
    return false;
  }
});

// console.log(times);

}

private removeOverlappingTime(times:string[], timeStart:string, duration:string){
  let timesM = [...times];

  let startTimeIndex = timesM.indexOf(timeStart);

  if(parseInt(duration) > 15)
  {
    
    let timeEndM = moment(timeStart,'HH:mm').add(duration, 'minutes');
    
    let prevTime = moment(timeStart,'HH:mm');
    let endIndex = timesM.indexOf(timeStart)

    for(let i = startTimeIndex + 1 ; i < timesM.length ; i++)
    {
      
      let timeCheck = moment(timesM[i], 'HH:mm');

      if(prevTime.add(15, 'minutes').isSame(timeCheck)){
        
        if(timeEndM.isAfter(timeCheck))
        {
          endIndex = i;
          prevTime = timeCheck;
        }else{
          break;
        }
      }else{
        break;
      }
    }
    // console.log(endIndex,startTimeIndex)
     timesM.splice(startTimeIndex, ((endIndex + 1) - startTimeIndex));

  }else{
    timesM.splice(startTimeIndex, 1);
  }
  // console.log(timeStart + '  '+ duration);
  // console.log([...timesM]);
  return [...timesM]
}

testselectRandomAviableStaffForAppointment()
{
  return this.selectAllAviableStaffForAppointment(
    {
      "56": {
        id_staff: 56,
        name_staff: "Stefania",
        avatar_staff:'',
        opening_hours_data: [
          "09:00",
          "09:15",
          "09:30",
          "09:45",
          "10:00",
          "10:15",
          "10:30",
          "10:45",
          "11:00",
          "11:15",
          "11:30",
          "11:45",
          "12:00",
          "12:15",
          "15:00",
          "15:15",
          "15:30",
          "15:45",
          "16:00",
          "16:15",
          "16:30",
        ],
        staff_appointments: {},
      },
      "57": {
        id_staff: 57,
        name_staff: "Vittoria",
        avatar_staff:'',
        opening_hours_data: [
          "14:00",
          "14:15",
          "14:30",
          "14:45",
          "15:00",
          "15:15",
          "15:30",
          "15:45",
          "16:00",
          "16:15",
          "16:30",
          "16:45",
          "17:00",
          "17:15",
          "17:30",
        ],
        staff_appointments: {
          "37": {
            id_appointment: 37,
            appointment_date: "2020-11-18 09:00:00",
            appointment_product_id: 27,
            appointment_product_duration: "160",
          },
        },
      },
    },
    "15:30",
    "30"
  );
  

}


//TO DO implement end h

selectAllAviableStaffForAppointment(staffs:AvailableStaffs , time:string, productDuration:string){
  // Get first staff witch is available
  // const dateTimeEnd = moment(time, 'HH:mm').add(productDuration, 'minutes').subtract(15, "minutes");
  // let chosenStaff:AvailableStaff;
  //  console.log(JSON.stringify(staffs));
  let availableStaffs = [];

  for(let staff in staffs){

    const indexStart = staffs[staff].opening_hours_data.indexOf(time.trim());
    // let prevH = moment(time, 'HH:mm');
    
    if(indexStart !== -1)
    {
      //Because data is already with duration aleready removed 
      availableStaffs.push(staff);

      // chosenStaff =  staffs[staff];
      //   if(parseInt(productDuration) > 15){
      //   // Check end time
        
      //   for(let i = indexStart + 1 ; i < staffs[staff].opening_hours_data.length ; i ++)
      //   {
      //     let currentTime = moment(staffs[staff].opening_hours_data[i], 'HH:mm');
      //     console.log('inside for')
      //     if(prevH.add('15', 'minutes').isSame(currentTime)){
      //       if(dateTimeEnd.isSame(currentTime) || dateTimeEnd.isBefore(currentTime)){
      //         chosenStaff =  staffs[staff];
      //         availableStaffs.push(chosenStaff);
      //         break;
      //       }
      //     }else{
      //       break;
      //     }
      //   }
      // }else{
      //    availableStaffs.push(chosenStaff);
      // }

    }
  }

  return availableStaffs;
}

saveNewAppointmentForCustomer(customer: Customer, productId:string, date:string, time:string, staffsIds: number[],chosenStaff:string, note:string){
  moment.locale('it-it');
  const dateTime = moment(date +' '+ time, 'DD/MM/YYYY HH:mm');
  const headers = { 'Accept': 'application/json', 'Authorization': 'Bearer '  + customer.token};

  const data = {
    'company_id' : customer.company_id,
    'product_id' : productId,
    'customer_id' : customer.customerId,
    'customer_name': customer.name + ' ' + customer.surname,
    'date' : dateTime.format('YYYY-MM-DD HH:mm:ss'),
    'users_ids': staffsIds.join(','),
    'chosen_user_id':chosenStaff,
    'note':note
  }

  // console.log(data);
  return this.http.post<apiResposeType>(this.glabalsConst.baseAppUrl + 'customer/appointments', data, {headers})
}
 
saveNewAppointmentForCustomerByStaff(
  customer: Customer,
  password:string, 
  user:User,  
  productId:string,
  productName:string,
  date:string, 
  time:string, 
  staffsIds: number[],
  chosenStaff:string, 
  note:string
   ){
  moment.locale('it-it');
 
  const dateTime = moment(date +' '+ time, 'DD/MM/YYYY HH:mm');
  const headers = { 'Accept': 'application/json', 'Authorization': 'Bearer '  + user.token};

  const data = {
    'company_id' : customer.company_id,
    'product_id' : productId,
    'product_name':productName,
    'customer_phone':customer.phone,
    'customer_name': customer.name ,
    'customer_surname':customer.surname,
    'customer_email':customer.email? customer.email : '',
    'customer_birthday':customer.birthday? customer.birthday: "1900-01-01 00:00:00",
    'password': password ? password : '',
    'date' : dateTime.format('YYYY-MM-DD HH:mm:ss'),
    'users_ids': staffsIds.join(','),
    'chosen_user_id':chosenStaff,
    'note':note
  }

  return this.http.post<apiResposeType>(this.glabalsConst.baseAppUrl + 'staff/customer/appointment', data, {headers})
}

editAppointmentForCustomer(customer: Customer, productId:string, date:string, time:string, staffsIds: number[],chosenStaff:string, note:string, appointment:Appointment){
  moment.locale('it-it');
  const dateTime = moment(date +' '+ time, 'DD/MM/YYYY HH:mm');
  const headers = { 'Accept': 'application/json', 'Authorization': 'Bearer '  + customer.token};
  
  const data = {
    'company_id':appointment.company_id,
    'product_id' : productId,
    'product_name' : appointment.product.name,
    'customer_name':customer.name + ' ' + customer.surname,
    'customer_id' : customer.customerId,
    'date' : dateTime.format('YYYY-MM-DD HH:mm:ss'),
    'users_ids': staffsIds.join(','),
    'chosen_user_id':chosenStaff,
    'note':note,
  }

  return this.http.post<apiResposeType>(this.glabalsConst.baseAppUrl + 'customer/appointments/'+ appointment.id +'/edit', data, {headers})
}

  onShowtToast(text: string){
    this._showtToast.next(text);
  }

  onCloseModal(id: string){
    // console.log('run')
    this._closeModal.next(id);
  }

  async closeAllModal()
  {

    let topLoader = await this.modalCtrl.getTop();
    while (topLoader) {
      await topLoader.setAttribute('animated', 'false');
      
      if (!(await topLoader.dismiss())) {
        throw new Error('Could not dismiss the topmost loader. Aborting...');
      }
      topLoader = await this.modalCtrl.getTop();
    }
  }

}
