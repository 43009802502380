import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import {  AlertController, NavController, Platform } from '@ionic/angular';
import { Location} from '@angular/common';
import { Observable, Subject } from 'rxjs';
import {  tap } from 'rxjs/operators';
import { apiResposeType } from '../models/apiResposeType';
import { Customer } from '../models/customer.model';
import { Product } from '../models/product.model';
import { User } from '../models/user.model';
import { AppointmentsService } from './appointments.service';
import { ConstantsService } from './constants.service';
import { LocalNotificationsService } from './local-notifications.service';
import { DeleatAppointment, NewAppointment } from '../models/interfacesHelper';
import { StaffNotification } from '../models/staffNotification.model';


@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {

  private _appointmentChanged: Subject<NewAppointment> = new Subject();
  appointmentChanged: Observable<NewAppointment> = this._appointmentChanged.asObservable();
  private _appointmentDeleated: Subject<DeleatAppointment> = new Subject();
  appointmentDeleated: Observable<DeleatAppointment> = this._appointmentDeleated.asObservable();
  private _newAppointment: Subject<NewAppointment> = new Subject();
  newAppointment: Observable<NewAppointment> = this._newAppointment.asObservable();
  private _staffNotification: Subject<StaffNotification> = new Subject();
  staffNotification: Observable<StaffNotification> = this._staffNotification.asObservable();

  constructor(
    private firebaseX: FirebaseX, 
    private platform: Platform,
    private http: HttpClient,
    private glabalsConst:ConstantsService,
    private router: Router,
    private zone:NgZone,
    private alertController: AlertController,
    private appointmentsService: AppointmentsService,
    private navCtrl: NavController,
    private location: Location,
    private localNotificationService: LocalNotificationsService
    ) { }


  checkOrAskPushPermission():Promise<boolean>{
    return new Promise((resolve,reject)=>{

      if(this.platform.is('cordova')){

        this.firebaseX.hasPermission().then(permission =>{

          if(permission){
            resolve(true);
          }else{
            this.firebaseX.grantPermission().then((hasPermission) => {
              if(hasPermission)
              {
                resolve(true);
              }else{

                resolve(false);
              }
              // console.log("Permission was " + (hasPermission ? "granted" : "denied"));
            }, error => {
              console.log(error);
              resolve(false);
            });
          }
    
        }, error => {
          console.log(error);
          resolve(false);
        });
      }else{
        resolve(false);
      }
    });
  }

  init(){
   
    // this.firebaseX.getToken()
    //   .then(token => console.log(`The token is ${token}`)) // save the token server-side and use it to push notifications to this device
    //   .catch(error => console.error('Error getting token', error));

    this.firebaseX.onTokenRefresh()
      // .subscribe((token: string) => console.log(`Got a new token ${token}`));

      this.firebaseX.onMessageReceived()
    // .subscribe(data => console.log(`User opened a notification ${data}`));
  }

  getToken(){
    return this.firebaseX.getToken();
  }

  onTokenRefresh(){
    return this.firebaseX.onTokenRefresh();
  }

  onMessageReceived(){
    return this.firebaseX.onMessageReceived().pipe(tap(data => {
      if(data){
        // console.log(data);
       
        if(data.hasOwnProperty('tap')){
          //From click
          if(data.hasOwnProperty('type')){
            switch(data.type) { 
              case 'CUSTOMER_EDIT_APPOINTMENT_DATE':
              case 'CUSTOMER_EDIT_APPOINTMENT_H':
              case 'CUSTOMER_EDIT_APPOINTMENT_NOTA':
              case 'CUSTOMER_NEW_APPOINTMENT': {  
                this.zone.run(async () => { 
                  this.router.navigateByUrl('/staff/appointments/appointment/'+ data.idAppointment);
                });
                break; 
              } 
              case 'CUSTOMER_DELEAT_APPOINTMENT': { 
                this.zone.run(async () => {
                  this.router.navigateByUrl('/staff/appointments')
                });
                break; 
              } 
              case 'STAFF_DELEAT_APPOINTMENT': {

                this.zone.run(async () => {
                
                  let incomingData = JSON.parse(data.attacheddData);

                  let product:Product = JSON.parse(incomingData.product);
                  //Check if customer has local notification on for this appointment

                  this.localNotificationService.checkIfLocalnotificationIsPresent(+incomingData.appointmenOldId).then(isPresent =>{
                    if(isPresent){

                      // console.log(isPresent + ' From STAFF_DELEAT_APPOINTMENT');
                      this.localNotificationService.deleatLocalNotification(+incomingData.appointmenOldId).then(()=>{});
                    }
                  });


                  this.alertController.create({
                    cssClass: 'my-custom-class',
                    header: incomingData.title,
                    message: incomingData.body + '<br>'+ 'Vuoi scegliere un altra data?',
                    backdropDismiss:false,
                    buttons: [
                      {
                        text: 'No',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: (blah) => {
                          // console.log('Confirm Cancel: blah');
                        }
                      }, {
                        text: 'Si',
                        handler: () => {
                          
                          let oldUrl = this.location.path();
                          this.navCtrl.navigateBack('/company/appointments').then(()=>{
                            this.navCtrl.navigateBack(oldUrl);
                            this.appointmentsService.onAddProductAndDate(product, incomingData.appointmentOldDate);
                          });
                        }
                      }
                    ],
                    mode:'ios'
                  }).then(elem => {
                    elem.present();
                  });


                }); 
                // console.log('Staff deleated appointment');
                break; 
              } 
              case 'STAFF_SEND_NOTIFICATION': {

                this.zone.run(async () => {
                
                
                  let uri =  this.location.path();

                  
                 
                  if(uri === '/company/company'){
                    let incomingData = JSON.parse(data.attacheddData);
                    let notification:StaffNotification = new StaffNotification();
                    if(incomingData.type === 'delete'){
                      notification.id = incomingData.id;
                      notification.type = incomingData.type;
                      this._staffNotification.next(notification);
                    }else if(incomingData.type === 'edit' || incomingData.type === 'new'){
                      let isIos = this.platform.is('ios');

                      notification.id = incomingData.id;

                      notification.title = !isIos ?  data.title : data.aps.alert.title;
                      notification.description = !isIos ? data.body : data.aps.alert.body;
                      notification.company_id = incomingData.company_id;
                      notification.date_start = incomingData.date_start;
                      notification.date_end = incomingData.date_end;
                      notification.updated_at = incomingData.updated_at;
                      notification.type = incomingData.type;
                      this._staffNotification.next(notification);
                    }

                  }else{
                    this.router.navigateByUrl('/company/company');

                  }
  
                });
                // console.log('Staff deleated appointment');
                break; 
              }

              default: { 
                 //statements; 
                break; 
              } 
           }
          }


        }else{
          //From app
          // console.log('From app');
          
          switch(data.type) { 
            case 'CUSTOMER_EDIT_APPOINTMENT_DATE':
            case 'CUSTOMER_EDIT_APPOINTMENT_H':
            case 'CUSTOMER_EDIT_APPOINTMENT_NOTA':
            { 
              //Update appointment
              this._appointmentChanged.next({
                idAppointment:data.idAppointment,
                userIdAppointment:data.userIdAppointment,
                customerIdAppointment:data.customerIdAppointment,
                productIdAppointment:data.productIdAppointment,
                dateAppointment:data.dateAppointment,
                noteAppointment: data.noteAppointment, 
                companyIdAppointment: data.companyIdAppointment,
                oldDateAppointment: data.oldDateAppointment ? data.oldDateAppointment : null
              });
              break; 
            } 
            case 'CUSTOMER_DELEAT_APPOINTMENT':
            { 
              //Update appointment
              this._appointmentDeleated.next({
                idAppointment:data.idAppointment,
                dateAppointment:data.dateAppointment
              });
              break; 
            } 
            case 'CUSTOMER_NEW_APPOINTMENT':{
              //Update appointment
              this._newAppointment.next({
                idAppointment:data.idAppointment,
                userIdAppointment:data.userIdAppointment,
                customerIdAppointment:data.customerIdAppointment,
                productIdAppointment:data.productIdAppointment,
                dateAppointment:data.dateAppointment,
                noteAppointment: data.noteAppointment, 
                companyIdAppointment: data.companyIdAppointment
              });
              break; 
            }
            case 'CUSTOMER_NEW_APPOINTMENT_BY_STAFF':{
              //Update appointment
              this._newAppointment.next({
                idAppointment:data.idAppointment,
                userIdAppointment:data.userIdAppointment,
                customerIdAppointment:data.customerIdAppointment,
                productIdAppointment:data.productIdAppointment,
                dateAppointment:data.dateAppointment,
                noteAppointment: data.noteAppointment, 
                companyIdAppointment: data.companyIdAppointment
              });
              break; 
            }

            case 'STAFF_DELEAT_APPOINTMENT': {
              
              let incomingData = JSON.parse(data.attacheddData)

              let product:Product = JSON.parse(incomingData.product);

              let uri =  this.location.path();
              
              //Check if customer has local notification on for this appointment

              this.localNotificationService.checkIfLocalnotificationIsPresent(+incomingData.appointmenOldId).then(isPresent =>{
                if(isPresent){
                  this.localNotificationService.deleatLocalNotification(+incomingData.appointmenOldId).then(()=>{});
                }
              });
              // console.log(JSON.parse(data.attacheddData));

              
              if(uri === '/company/customer/appointments'){
                let attacheddData = JSON.parse(data.attacheddData);
                this._appointmentDeleated.next({idAppointment:attacheddData.appointmenOldId, dateAppointment: attacheddData.appointmentOldDate});
              }



              console.log('Staff deleated appointment and you are in app')


              break; 
            }  

            case 'STAFF_SEND_NOTIFICATION': {

              this.zone.run(async () => {
           
                let uri =  this.location.path();
             
                // if(uri === '/company/company'){
                  let incomingData = JSON.parse(data.attacheddData);
                  let notification:StaffNotification = new StaffNotification();
                  if(incomingData.type === 'delete'){
                    notification.id = incomingData.id;
                    notification.type = incomingData.type;
                    this._staffNotification.next(notification);
                  }else if(incomingData.type === 'edit' || incomingData.type === 'new'){
                    let isIos = this.platform.is('ios');

                    notification.id = incomingData.id;
                    notification.title = !isIos ?  data.title : data.aps.alert.title;
                    notification.description = !isIos ? data.body : data.aps.alert.body;
                    notification.company_id = incomingData.company_id;
                    notification.date_start = incomingData.date_start;
                    notification.date_end = incomingData.date_end;
                    notification.updated_at = incomingData.updated_at;
                    notification.type = incomingData.type;
                    this._staffNotification.next(notification);
                  }



                // }

              }); 
              break; 
            }
            
            default: { 
               //statements; 
              break; 
            } 
         }
        }

      }
    }));
  }



  saveToken(user:User, customer:Customer, token:string){
      if(user){
        //Save token for user

        const headers = { 'Accept': 'application/json', 'Authorization': 'Bearer '  + user.token};

        const data = {
          'company_id' : user.companyId,
          'token' : token,
        }
        
        return this.http.post<apiResposeType>(this.glabalsConst.baseAppUrl + 'staff/' + user.id + '/fbtoken', data, {headers});


      }else{
        //Save token for customer

        const headers = { 'Accept': 'application/json', 'Authorization': 'Bearer '  + customer.token};

        const data = {
          'company_id' : customer.company_id,
          'token' : token,
        }
        
        return this.http.post<apiResposeType>(this.glabalsConst.baseAppUrl + 'customer/' + customer.customerId + '/fbtoken', data, {headers});
      }
  }

  removeToken(user:User, customer:Customer){
      if(user){
        //Remove token for user

        const headers = { 'Accept': 'application/json', 'Authorization': 'Bearer '  + user.token};

        const data = {
          'company_id' : user.companyId,
          'token':user.fbToken
        }
        
        return this.http.post<apiResposeType>(this.glabalsConst.baseAppUrl + 'staff/' + user.id + '/fbtoken/destroy', data, {headers})

      }else{
        //Remove token for customer

        const headers = { 'Accept': 'application/json', 'Authorization': 'Bearer '  + customer.token};

        const data = {
          'company_id' : customer.company_id,
          'fb_token':customer.fbToken
        }
        
        return this.http.post<apiResposeType>(this.glabalsConst.baseAppUrl + 'customer/' + customer.customerId + '/fbtoken/destroy', data, {headers})
      }
  }
}