import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService { 
    //  readonly baseAppUrl: string = 'http://laravelseven.test/api/';
    // readonly baseAppUrl: string = 'http://95.110.228.205/api/';
     readonly baseAppUrl: string = 'https://my.ioprenotofacile.it/api/';
    // readonly baseAppUrl: string =  'http://my.ioprenotofacile.test/api/';
    // readonly baseAppUrl: string = 'https://my.barbapp.it/api/';

    readonly appCrmId: string = '781';
    
  constructor() { }
}
