import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';

const LANG_KEY = 'SELECTED_LANGUAGE'

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  selected="";

  constructor(
    private translate:TranslateService,
    private storage: Storage,
    private plt: Platform
  ) { }

  setInitialLanguage(){
    let language = this.translate.getBrowserLang();
    this.translate.setDefaultLang("it");

    this.storage.get(LANG_KEY).then(val => {
      if(val){
        this.setLanguage(val);
        this.selected = val;
      }
    });
  }

  getLanguages(){
    return [
      {text: 'Italiano', value:'it'},
      {text: 'Inglese', value:'en'}
    ]
  }

  setLanguage(lng){
    this.translate.use(lng);
    this.selected = lng;

    this.storage.set(LANG_KEY, lng);
  }
}
