import { Injectable } from '@angular/core';
import {  UrlTree, CanLoad, Route, UrlSegment, Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { AuthUserService } from '../services/auth-user.service';
import { CompanyService } from '../services/company.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad, CanActivate {

  constructor(
    private authService: AuthService ,
    private router: Router,
    private companyService:CompanyService, 
    private staffAuthService: AuthUserService
    ){}
  
  
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree{
    return this.companyService.getCompany().then(company =>{
      
      if(!company){
        this.router.navigateByUrl("/auth");
        return false;
      }else{
        // this.router.navigateByUrl("/company");
        return this.staffAuthService.getStaff().then(user => {

          if(!user){
            return true;
          }else{
            this.router.navigateByUrl("/staff");
            return false;
          }
          
        });
        
      }
    })
/*     if(!this.authService.userHasCompany){
      this.router.navigateByUrl("/auth");
      
    }
    return this.authService.userHasCompany; */
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.companyService.getCompany().then(company =>{
      
      if(!company){
        this.router.navigateByUrl("/auth");
        return false;
      }else{
        // this.router.navigateByUrl("/company");
        return this.staffAuthService.getStaff().then(user => {

          if(!user){
            return true;
          }else{
            this.router.navigateByUrl("/staff");
            return false;
          }
          
        });
        
      }
    })
  }

}
