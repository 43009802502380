

export class StaffNotification
{
  constructor(
    public id: string = '',
    public company_id: string = '',
    public user_id: string = '',
    public title: string = '',
    public description: string = '',
    public date_start: string = '',
    public pretty_date_start:string = '',
    public pretty_h_start:string = '',
    public date_end: string = '',
    public pretty_date_end:string = '',
    public pretty_h_end:string = '',
    public created_at: string = '',
    public updated_at: string = '',
    public type?:string
  ){}
  
}