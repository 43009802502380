import { Directive } from '@angular/core';
import {Validator, NG_VALIDATORS, ValidatorFn, FormControl} from '@angular/forms';

@Directive({
  selector: '[appPhonevalidator]',  
  providers: [  
    {  
      provide: NG_VALIDATORS,  
      useClass: PhonevalidatorDirective,  
      multi: true  
    }  
  ] 
})
export class PhonevalidatorDirective implements Validator{

  validator: ValidatorFn;  
  constructor() {  
    this.validator = this.phoneValidator();  
  }  
  
  validate(c: FormControl) {  
    return this.validator(c);  
  }  
  
  phoneValidator(): ValidatorFn {  
    return (control: FormControl) => {  
      if (control.value != null && control.value !== '') {  
        let isValid = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(control.value);  
        if (isValid) {  
          return null;  
        } else {  
          return {  
            phoneValidator: { valid: false }  
          };  
        }  
      } else {  
        return null;  
      }  
    };  
  } 

}
