import { Component } from '@angular/core';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import { AlertController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthCustomerService } from './services/auth-customer.service';
import { AuthUserService } from './services/auth-user.service';
import { LanguageService } from './services/language.service';
import { AppInfoService } from './services/app-info.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent  {

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authCustomerService: AuthCustomerService,
    private authUserService: AuthUserService,
    private languageService: LanguageService,
    private alertController: AlertController,
    private appInfoService: AppInfoService,
    private iab: InAppBrowser
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      // this.statusBar.styleDefault();
      this.statusBar.styleBlackTranslucent();
      this.splashScreen.hide();

      this.languageService.setInitialLanguage();
      //Check update need

      this.appInfoService.getAppVersion().then(version =>{
        // console.log(version);
        let platformType = 1;
        if(this.platform.is('ios')){
          platformType = 2;
        }

        this.appInfoService.getAppVersionStore(version, platformType).subscribe({
          next: respose => {
           
            if(respose.success){
              if(respose.data.hasOwnProperty('Details')){
                
                if(respose.data.Details.ID === '200OK'){
                  
                  if(respose.data.hasOwnProperty('Result')){
                    if(respose.data.Result.hasOwnProperty('Release')){
                      // if(respose.data.Result.Release.trim() !== version.trim()){
                      if(respose.data.Result.Release.trim() !== version.trim()){
                        this.appInfoService.getAppName().then(name=>{
                             this.versionUpgrateAllert(name);
                          });
                      }
                    }
                  }
                }
              }
            }
          },
          error: err => {
            console.log(err)
          },
        });

        // if(version !== this.appInfoService.getAppVersionStore()){
        //   // console.log('run');
        //   this.appInfoService.getAppName().then(name=>{
        //    this.versionUpgrateAllert(name);
        //   });
        // }
      });
     
    });
  }
  ngOnInit() {
    //Check if is logged in as customer
    this.authCustomerService.customer.then(customer => {

      if(customer){
        //Logged in as customer
        //Init token for customer
        this.authCustomerService.initFirebaseNotificationsForCustomer();
        
        // console.log('Customer logged in', customer);
      }else{
        // Check if logged in as user

        this.authUserService.getUser().then(staff => {
          if(staff){
            //Logged in as Staff

            this.authUserService.initFirebaseNotificationsForStaff();
            // console.log('CusStafftomer logged in', staff);


          }else{
            //Is not logged in
            // console.log('not logged in');

          }

        });
      }

    });
  }

  versionUpgrateAllert(name:string){
    console.log(name)
    this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Aggiornamento disponibile ',
      message: 'La tua app '+name+' migliora ogni giorno. Per usufruire di tutte le nuove funzionalità effettua subito l\'aggiornamento.',
      // message: name + ' si è rifatta il look. Per usufruire di tutte le nuove funzionalità ti consigliamo di effettuare l\'aggiornamento',
      backdropDismiss:false,
      mode:'ios',
      buttons: [
        {
          text: 'Continua senza aggiornare',
          handler: () => {
            
            
          }
        },
        {
          text: 'Aggiorna',
          handler: () => {
            if(this.platform.is('ios')){
              
              this.iab.create('itms-apps://itunes.apple.com/app/id1545899320', '_blank')

            }else{

              this.iab.create('https://play.google.com/store/apps/details?id=com.gecsoftware.barbers', '_system')
            }
            
          }
        }
      ]
    }).then(alertElem => {
      alertElem.present();
    });
  }

  ngOnDestroy(){

  }
}
