import { Injectable } from '@angular/core';
import {Company} from '../models/company.model';
import { Storage } from '@ionic/storage';
import { apiResposeType } from '../models/apiResposeType';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from './constants.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  //Company

  private _company:Company;
  // = new Company('1','Gec Software','https://www.gecsoftware.it/wp-content/themes/gecsoftware/assets/images/logos/logo_gec.png','GEC Software si occupa di Software, strumenti e formazione professionale per gli studi tecnici e i professionisti dell\'edilizia.','Via Nazionale Torrette, 98/scala a, 83013 Mercogliano AV','5123512','dfasfsad',null);

  constructor(
    private storage:Storage,
    private http: HttpClient,
    private glabalsConst:ConstantsService,
    ) { }

  getCompany():Promise<Company>{
    return new Promise((resolve,reject)=>{
      if(this._company){
        //console.log(this._company);
        resolve(this._company)
      }else{
        return this.storage.get('company').then(company => {
          //console.log(this._company);
          this._company = company;
          resolve(company);
        });
      }
    })
  }

  storeCompany(company:Company): Promise<Company> {

    return this.storage.set('company', company).then( respose => {
      if(respose === company){
        this._company = company;
        return respose;
      }else{
        return null;
      }
    });
  }

  removeCompany(){
    return this.storage.remove('company').then(()=>{
      this._company = null;
      return null;
    });
  }

  removeCompanyWithPromise(){
    return new Promise((resolve,reject)=>{
      this.storage.remove('company').then((company)=>{
        this._company = null;
        resolve(company);
      });
    });
    
  }

  fetchCompany(id: string){
    return this.http.get<apiResposeType>(this.glabalsConst.baseAppUrl + 'companies/' + id)
      .pipe(
        map(respose => {
          if(!respose || !respose.success){
            return null;
          }
          this._company = respose.data;
          respose.data.openingHours = JSON.parse(respose.data.openingHours);
          return respose;

        })
    );
  }
}
