import { Injectable } from '@angular/core';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { Storage } from '@ionic/storage';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class LocalNotificationsService {

  constructor(
    private localNotifications: LocalNotifications,
    private nativeSetings: OpenNativeSettings,
    private storage: Storage
    ) { }


    //Check max time available for local notification

    maxTimeAvailableForLocalNotification(dateInput:string,  timeInput:string):number{
    
      moment.locale('it-it');
      let present = moment();
      let appointmentTime = moment(dateInput +' '+ timeInput, 'DD/MM/YYYY HH:mm');
      

      if(present.isBefore(appointmentTime)){
        let ms = appointmentTime.diff(present);
        let duration = moment.duration(ms);
        let availableHBefore:number = Math.floor(duration.asHours());

        return availableHBefore;

      }else{
        return 0;
      }

      return 1
    }

  checkNotificationPermision(){
    return new Promise((resolve,reject)=>{

      this.localNotifications.hasPermission().then((granted) => { 

        if(!granted){
          this.storage.get('firstNotificationPermission').then(respose => {
            if(respose === null){
              //Handle first time request
              this.storage.set('firstNotificationPermission', false).then(()=>{
                this.localNotifications.requestPermission().then(resp =>{

                  if(resp){
                    resolve(resp);
                  }else{
                    resolve(null)
                  }

                  
                });
              });
              
            }else{
              //Handle not first time request
  
               if(!granted){
                //check if first time
                resolve(false);
                
  
  
              }else{
                resolve(true);
              }
            }
          });
        }else{
          resolve(true);
        }

      });
    });
    
 }

  openNativeNotificationSettings(){
    this.nativeSetings.open('notification_id').then((checkVal)=>{
      // console.log(checkVal);
    });
  }

  setNotificaAppuntamento(productName:string, customerName:string, appointmentDate: string, appointmentTime:string, appointmentId:string, timeBefore:number){
   
  moment.locale('it-it');
  let dateTimeToSend = moment(appointmentDate +' '+ appointmentTime, 'DD/MM/YYYY HH:mm');
  dateTimeToSend.subtract(timeBefore,'hours');

    //Cancel if exist already
    this.localNotifications.isPresent(+appointmentId).then(isPresent =>{
      if(isPresent){
        this.localNotifications.cancel(+appointmentId).then(()=>{
          this.localNotifications.schedule({
            id:+appointmentId,
            title: 'Promemoria',
            text: 'Ciao '+ customerName +', volevamo ricordarti l\'appuntamento '+ appointmentDate +' alle ore '+ appointmentTime +' per '+ productName +'. Ti aspettiamo.',
            trigger: {at: dateTimeToSend.toDate()},
            foreground: true,   //this seems to be needed on iOS
            led: 'FF0000',
            // sound: null
          });
        })
      }else{
        this.localNotifications.schedule({
          id:+appointmentId,
          title: 'Promemoria',
          text: 'Ciao '+ customerName +', volevamo ricordarti l\'appuntamento '+ appointmentDate +' alle ore '+ appointmentTime +' per '+ productName +'. Ti aspettiamo.',
          trigger: {at: dateTimeToSend.toDate()},
          foreground: true,   //this seems to be needed on iOS
          led: 'FF0000',
          // sound: null
        });
      }
    });
  }

  checkIfLocalnotificationIsPresent(id:number){
    return this.localNotifications.isPresent(id);
  }
  
  deleatLocalNotification(id:number){
    return this.localNotifications.cancel(id);
  }
}
