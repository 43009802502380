import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Platform } from '@ionic/angular';
import { apiResposeType } from '../models/apiResposeType';
import { ConstantsService } from './constants.service';

@Injectable({
  providedIn: 'root'
})
export class AppInfoService {

  constructor(
    private appVersion: AppVersion,
    private platform:Platform, 
    private http: HttpClient,
    private glabalsConst:ConstantsService,
  ) { }

  getAppVersionStore(version:string, platformType:number){
    const data = {
      relese : version,
      market:platformType,
      app_id : this.glabalsConst.appCrmId
    }

    const headers = { 'Accept': 'application/json'};

    return this.http.post<apiResposeType>(this.glabalsConst.baseAppUrl + 'app/version', data, {headers});


    
  }

  getAppVersion():Promise<string>{
    return new Promise((resolve,reject)=>{
    if(this.platform.is('cordova')){
      this.appVersion.getVersionNumber().then(version => {
        if(version){
          
          resolve(version);
        }else{
          resolve('0.0.1');
          
        }
      });
     }else{
      resolve('0.0.1');
     }
    });
  }

  getAppName():Promise<string>{
    return new Promise((resolve,reject)=>{
    if(this.platform.is('cordova')){
      this.appVersion.getAppName().then(name => {
        if(name){
          
          resolve(name);
        }else{
          resolve('Static name');
          
        }
      });
     }else{
      resolve('Static name');
     }
    });
  }


}
