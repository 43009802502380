import { HttpClient } from '@angular/common/http';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { apiResposeType } from 'src/app/models/apiResposeType';
import { Customer } from 'src/app/models/customer.model';
import { StaffNotification } from 'src/app/models/staffNotification.model';
import { User } from 'src/app/models/user.model';
import { ConstantsService } from '../constants.service';
import { PushNotificationsService } from '../push-notifications.service';



@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private _resetStaffNotificationCounter: Subject<string> = new Subject();
  resetStaffNotificationCounter: Observable<string> = this._resetStaffNotificationCounter.asObservable();

  constructor(
    private http: HttpClient,
    private glabalsConst:ConstantsService,
    private pushNotifications: PushNotificationsService,
    
  ) { }

  fatchNotificationById(id:number, user:User){
    const data = {
      company_id : user.companyId,
      user_id: user.id,
    }

    const headers = { 'Accept': 'application/json', 'Authorization': 'Bearer '  + user.token};

    moment.locale('it-it');

    return this.http.post<apiResposeType>(this.glabalsConst.baseAppUrl + 'staff/notifications/' + id, data, {headers}).pipe(
      map(data => {

        if(data.success)
        {
          let notification:StaffNotification = data.data.notification;
          let dateStartMoment = moment(data.data.notification.date_start);
          let dateEndMoment = moment(data.data.notification.date_end);

          notification.pretty_date_start = dateStartMoment.format('DD MMM YYYY');
          notification.pretty_h_start = dateStartMoment.format('HH:mm');
          notification.pretty_date_end = dateEndMoment.format('DD MMM YYYY');
          notification.pretty_h_end = dateEndMoment.format('HH:mm');
          data.data.notification = notification;

          return data;


        }else{
          return data;
        }

      })
    );
  }


  fatchAllNotifications(user:User){

    const data = {
      company_id : user.companyId,
      user_id: user.id,
    }

    const headers = { 'Accept': 'application/json', 'Authorization': 'Bearer '  + user.token};

    moment.locale('it-it');
    return this.http.post<apiResposeType>(this.glabalsConst.baseAppUrl + 'staff/notifications', data, {headers}).pipe(
      map(data => {
       
        if(data.success){
          let notificationsEnded:StaffNotification[] = [];
          let notificationsNew:StaffNotification[] = [];
          let date = moment();

          for(let i = 0; i< data.data.notification.length ; i++)
          {
            let dateStartMoment = moment(data.data.notification[i].date_start);
            let dateEndMoment = moment(data.data.notification[i].date_end);
            data.data.notification[i].pretty_date_start = dateStartMoment.format('DD MMM YYYY');
            data.data.notification[i].pretty_h_start = dateStartMoment.format('HH:mm');
            data.data.notification[i].pretty_date_end = dateEndMoment.format('DD MMM YYYY');
            data.data.notification[i].pretty_h_end = dateEndMoment.format('HH:mm');

            if(date.isSameOrBefore(dateEndMoment))
            {
              notificationsNew.push(data.data.notification[i]);
            }else{
              notificationsEnded.push(data.data.notification[i]);
            }
          }

          return {notificationsEnded:notificationsEnded, notificationsNew:notificationsNew}


        }else{
          return {notificationsEnded:[], notificationsNew:[]};
        }
        
      })
    );
  }

  fatchAllNotificationsForCustomer(company_id:string, customer_id:string){

    const data = {
      company_id : company_id,
      now_date: moment().format('YYYY-MM-DD HH:mm:ss'),
      customer_id:customer_id
    }

    const headers = { 'Accept': 'application/json'};

    moment.locale('it-it');

   

    return this.http.post<apiResposeType>(this.glabalsConst.baseAppUrl + 'companies/'+ company_id +'/notifications', data, {headers}).pipe(
      map(data => {
       
        if(data.success)
        {

          let notifications:StaffNotification[] = [];
          for(let i = 0; i< data.data.notifications.length ; i++)
          {
            let dateStartMoment = moment(data.data.notifications[i].date_start);
            let dateEndMoment = moment(data.data.notifications[i].date_end);
            data.data.notifications[i].pretty_date_start = dateStartMoment.format('DD MMM YYYY');
            data.data.notifications[i].pretty_h_start = dateStartMoment.format('HH:mm');
            data.data.notifications[i].pretty_date_end = dateEndMoment.format('DD MMM YYYY');
            data.data.notifications[i].pretty_h_end = dateEndMoment.format('HH:mm');
            notifications.push(data.data.notifications[i]);
            
          }
          
          data.data.notifications = notifications;
          return data;

        }else
        {
          return data;
        }
        
      })
    );
  }

  resetUnvisitedForCustomer(customer:Customer){
    this._resetStaffNotificationCounter.next('reset');
    // const data = {
    //   company_id : customer.company_id,
    // }

    // const headers = { 'Accept': 'application/json', 'Authorization': 'Bearer '  + customer.token};

    // return this.http.post<apiResposeType>(this.glabalsConst.baseAppUrl + 'customer/'+ customer.customerId+'/notifications/reset', data, {headers});
  }



  saveNewOrEditNotification(
    user: User,
    title: string,
    description: string,
    dateStart: string,
    timeStart:string,
    dateEnd: string,
    timeEnd:string,
    notificationId:string = null
     ){
   moment.locale('it-it');
    let timeStartN = timeStart;
    let dateEndN = dateEnd;
    let timeEndN = timeEnd;


    if( timeStartN === "" || dateEndN === "" || timeEndN === "")
    {
      let dateStartMoment = moment(dateStart);
      if(timeStartN === ""){
        timeStartN = dateStartMoment.clone().set({
          hour:   0,
          minute: 0,
        }).toISOString();
      }

      if(dateEndN === ""){
        dateEndN = dateStartMoment.clone().toISOString();
      }

      if(timeEndN === ""){
        timeEndN = moment(dateEndN).clone().set({
          hour:   23,
          minute: 59,
        }).toISOString();
      }
    }


    let dateTimeStart = moment(dateStart).set({
    hour:   moment(timeStartN).get('hour'),
    minute: moment(timeStartN).get('minute'),
    second:0
    });

    let dateTimeEnd = moment(dateEndN).set({
    hour:   moment(timeEndN).get('hour'),
    minute: moment(timeEndN).get('minute'),
    second:0
    });

    const headers = { 'Accept': 'application/json', 'Authorization': 'Bearer '  + user.token};
  
    const data = {
      company_id : user.companyId,
      user_id: user.id,
      title: title,
      description: description,
      date_start: dateTimeStart.format('YYYY-MM-DD HH:mm:ss'),
      date_end: dateTimeEnd.format('YYYY-MM-DD HH:mm:ss')
    }

    if(notificationId === null){
      return this.http.post<apiResposeType>(this.glabalsConst.baseAppUrl + 'staff/notification', data, {headers});
    }else{
      return this.http.post<apiResposeType>(this.glabalsConst.baseAppUrl + 'staff/notifications/'+ notificationId+'/edit', data, {headers});
    }
  
    
  }

  deleteNotification(user:User, notificationId:string){
    
    const headers = { 'Accept': 'application/json', 'Authorization': 'Bearer '  + user.token};
  
    const data = {
      company_id : user.companyId,
      user_id: user.id,
    }

    return this.http.post<apiResposeType>(this.glabalsConst.baseAppUrl + 'staff/notifications/'+ notificationId+'/delete', data, {headers});
    
  }

  onStaffNotification(){
    return this.pushNotifications.staffNotification.pipe(map( notification => {
     
        if(notification.type === 'edit' || notification.type === 'new'){
          let dateStartMoment = moment(notification.date_start);
          let dateEndMoment = moment(notification.date_end);
          notification.pretty_date_start = dateStartMoment.format('DD MMM YYYY');
          notification.pretty_h_start = dateStartMoment.format('HH:mm');
          notification.pretty_date_end = dateEndMoment.format('DD MMM YYYY');
          notification.pretty_h_end = dateEndMoment.format('HH:mm');

          return notification;

        }else{
          return notification;
        }


    }));
  }

 
}
