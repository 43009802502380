import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { QRScanner } from '@ionic-native/qr-scanner/ngx';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { IonicStorageModule } from '@ionic/storage';
import { CalendarModule } from 'ion2-calendar';

import * as Hammer from 'hammerjs';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';


import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { SideMenuComponent } from './side-menu/side-menu.component';
import { PhonevalidatorDirective } from './directive/phonevalidator.directive';
// import { TimeConvertorPipe } from './pipes/time-convertor.pipe';
// import { MomentDateFormaterPipe } from './pipes/moment-date-formater.pipe';


// import { FullCalendarModule } from '@fullcalendar/angular';
// import timeGridPlugin from '@fullcalendar/timegrid';
// import dayGridPlugin from '@fullcalendar/daygrid';

// import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
// import { fas } from '@fortawesome/free-solid-svg-icons'
// import { far } from '@fortawesome/free-regular-svg-icons'
// import { fab } from '@fortawesome/free-brands-svg-icons';

// FullCalendarModule.registerPlugins([
//   dayGridPlugin,
//   timeGridPlugin

// ]);

import { AppVersion } from '@ionic-native/app-version/ngx';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient,'assets/i18n/', '.json');
}


@NgModule({
  declarations: [AppComponent, PhonevalidatorDirective, SideMenuComponent, ],
  entryComponents: [],
  imports: [BrowserModule, 
    IonicModule.forRoot(), 
    IonicStorageModule.forRoot(), 
    AppRoutingModule, 
    CalendarModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    // FullCalendarModule,
    // FontAwesomeModule,
    HammerModule,
    
  ],
  providers: [
    StatusBar,
    FirebaseX,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    QRScanner,
    LocalNotifications,
    OpenNativeSettings,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    AppVersion,
    InAppBrowser
  ],
  bootstrap: [AppComponent],

})
export class AppModule {
  // constructor(library: FaIconLibrary) { 
	// 	library.addIconPacks(fas, fab, far);
	// }
}
