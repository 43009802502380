import { Injectable } from '@angular/core';
import { UrlTree, CanLoad, UrlSegment, Router, Route, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthUserService } from '../services/auth-user.service';
import { CompanyService } from '../services/company.service';

@Injectable({
  providedIn: 'root'
})
export class AuthUserGuard implements CanLoad, CanActivate {

  constructor(
    private router: Router,  
    private companyService:CompanyService, 
    private staffAuthService: AuthUserService 
    ){}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

    return this.staffAuthService.getStaff().then(user =>{
      
      if(!user){
        return this.companyService.getCompany().then(company =>{

          if(company){
            this.router.navigateByUrl("/company");
          }else{
            this.router.navigateByUrl("/auth");
          }
          
          return false;
        });
        
      }else{
        // this.router.navigateByUrl("/company");
        return true;
      }
    })
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree{
    return this.staffAuthService.getStaff().then(user =>{
      if(!user){
        return this.companyService.getCompany().then(company =>{

          if(company){
            this.router.navigateByUrl("/company");
          }else{
            this.router.navigateByUrl("/auth");
          }
          
          return false;
        });
        
      }else{
        // this.router.navigateByUrl("/company");
        return true;
      }
    })
/*     if(!this.authService.userHasCompany){
      this.router.navigateByUrl("/auth");
      
    }
    return this.authService.userHasCompany; */
  }
  
}
