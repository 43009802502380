import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, take, tap } from 'rxjs/operators';
import { ConstantsService } from './constants.service';
import { Storage } from '@ionic/storage';
import { Customer } from '../models/customer.model';
import { apiResposeType } from '../models/apiResposeType';
import { PushNotificationsService } from './push-notifications.service';
import { User } from '../models/user.model';


interface CurrentCustomer
{
  phone:string
}

@Injectable({
  providedIn: 'root'
})
export class AuthCustomerService { 
  //Customer

  private _customer:Customer;

  constructor(private http: HttpClient,
    private glabalsConst:ConstantsService,
    private storage: Storage,
    private pushNotifications: PushNotificationsService
    ) { }

    get customer():Promise<Customer | null>{
      return new Promise((resolve,reject)=>{
        if(this._customer){
          resolve(this._customer)
        }else{
          return this.storage.get('authCustomerData').then(customer => {
            this._customer = customer;
            resolve(customer);
          });
        }
      })
    }  


  appointmentChanged(){
    return this.pushNotifications.appointmentChanged;
  }

  appointmentDeleated(){

    return this.pushNotifications.appointmentDeleated;
  }

  newAppointment(){
    return this.pushNotifications.newAppointment
  }

  signUp(name:string, surname:string, phone:string, email:string, birthDay:string, companiId: string){

    return this.http.post<apiResposeType>(this.glabalsConst.baseAppUrl + 'customer/sign-up', {
      name:name,
      surname:surname, 
      phone: phone, 
      email: email,
      birthDay: birthDay,
      company_id: companiId
    } );
    // .pipe(
    //   map((data) => {
    //     return data;
        
    //   }),
    //   tap((availableH) => {
    //     // this._availableHTodayAndTomorow.next(availableH);
    //   })
    // ); 
  }

  signIn(phone: string, password: string){
    return this.http.post<apiResposeType>(this.glabalsConst.baseAppUrl + 'customer/sign-in', {phone: phone, password: password} ).pipe(
      map((data) => {
        if(data.success){
          const customer:Customer = {
            customerId:data.data.customer.id,
            name:data.data.customer.name,
            phone:data.data.customer.phone,
            surname:data.data.customer.surname ,
            company_id:data.data.customer.company_id,
            token:data.data.token,
            fbToken: data.data.customer.fb_token,
            unvisitedNrNotifications: data.data.customer.unvisited_notifications
          }

          return customer
        }else{
          return null;
        }  
      }),
      tap((customer) => {
        this._customer = customer;
        this.storeAuthCustomerData(customer);
        this.initFirebaseNotificationsForCustomer();
      })
    ); 
  }

  sendSmsForPassword(customer:CurrentCustomer){
    return this.http.post<apiResposeType>(this.glabalsConst.baseAppUrl + 'customer/login_request', {phone: customer.phone} );
  }

  recoverPassword(email:string,phone:string, companyId:string){
    return this.http.post<apiResposeType>(this.glabalsConst.baseAppUrl + 'customer/recover', {email: email, phone:phone, company_id: companyId}); 
  }

  storeAuthCustomerData(customer:Customer){
  
    return new Promise((resolve,reject)=>{
      this.storage.set('authCustomerData', customer).then(data => {
        resolve(data);
      });
    });
     
  }

  removeCustomer(){
    return new Promise((resolve,reject)=>{

      //Remove token Associated with customer
      this.customer.then(customer => {

        if(customer && customer.fbToken){
          
          this.removeFBTokenForCustomer(customer).pipe(take(1)).subscribe(
            {
              next: resp => {
                // console.log(resp);
              },
              error: err => {
                console.log(err);
              }
            }
          );
        }

        this.storage.remove('authCustomerData').then(customer => {
          this._customer = null;
          resolve(customer);
        });
      });
    })
  }

  getAllCustomers(user:User){
    const headers = { 'Accept': 'application/json', 'Authorization': 'Bearer '  + user.token};

    const data = {
      'company_id' : user.companyId,
    
    }
        
    return this.http.post<apiResposeType>(this.glabalsConst.baseAppUrl + 'staff/customers', data, {headers}).pipe(
      map(data => {
        if(!data.success){
          return [];
        }
        return data.data.customers;
      })
      );
  }

  getAllCustomersPhoneAndNames(companyId:string){

    const data = {
      'company_id' : companyId,
    }

    return this.http.put<apiResposeType>(this.glabalsConst.baseAppUrl + 'customer/all/phone_name', data).pipe(
      map(data => {
        if(!data.success){
          return [];
        }
        return data.data;
      })
    );
  }


  initFirebaseNotificationsForCustomer(){
    this.pushNotifications.checkOrAskPushPermission().then(permission =>{
      if(permission){
        this.pushNotifications.getToken().then(token => {
          // console.log(token);
          if(token !== null){
            this.customer.then(customer => {
              if(customer){
                // console.log('Old from get' + customer.fbToken);
                // console.log('New from get' + token);
                if(customer.fbToken !== token){
                  //Save the new token
                  this.saveNewFBTokenForCustomer(customer, token);

                }else{
                  //Token are same, do nothing

                }
              }

            });
          }
  
        });


        this.pushNotifications.onTokenRefresh().subscribe({
          next: token => {
            // Check if customer Token is same as online Token
             

            this.customer.then(customer => {
              if(customer){
                // console.log('Old ' + customer.fbToken);
                // console.log('New ' + token);
                if(customer.fbToken !== token){
                  //Save the new token
                  this.saveNewFBTokenForCustomer(customer, token);

                }else{
                  //Token are same, do nothing

                }


              }

            });

          },

          error: err => {
            console.log(err);
          }
        })


        this.pushNotifications.onMessageReceived().subscribe(data => {
          //Handler messages
          // console.log(`User opened a notification ${data}`);
          // console.log(data);
        });

      }else{

        // console.log('Dont have permission for push notification')
      }
    });
  }

  saveNewFBTokenForCustomer(customer: Customer, newToken:string){

    this.pushNotifications.saveToken(null, customer, newToken).subscribe(
      {
        next: resp => {
          if(resp.success){

            this._customer.fbToken = newToken;
            this.storeAuthCustomerData(this._customer);

          }
        },
        error: err => {
          console.log(err);
        }
      }

    );
  }

  removeFBTokenForCustomer(customer: Customer){
    return this.pushNotifications.removeToken(null, customer)
  }

  syncCustomer(customer:Customer){
    const headers = { 'Accept': 'application/json', 'Authorization': 'Bearer '  + customer.token};
    console.log(customer.token);

    const data = {
      'company_id' : customer.company_id,
      'customer_id': customer.customerId
    
    }
        
    return this.http.post<apiResposeType>(this.glabalsConst.baseAppUrl + 'customer/'+customer.customerId+'/sync', data, {headers}).pipe(
      map((data) => {
        if(data.success){

          let customerSync = customer;
          customerSync.name = data.data.name;
          customerSync.surname = data.data.surname;
          customerSync.unvisitedNrNotifications = data.data.unvisited_notifications;
         


          // const customer:Customer = {
          //   customerId:data.data.id,
          //   name:data.data.name,
          //   phone:data.data.phone,
          //   surname:data.data.surname ,
          //   company_id:data.data.company_id,
          //   token:data.data.token,
          //   fbToken: data.data.fb_token,
          //   unvisitedNrNotifications: data.data.unvisited_notifications
          // }

          return customerSync;
        }else{
          return null;
        }  
      }),
      tap((customer) => {
        if(customer){
          this._customer = customer;
          this.storeAuthCustomerData(customer);
        }
        
      })
      );
  }


}
